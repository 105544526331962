#footer {
  width: 828px;
  height: 23px;
  line-height: 23px;
  margin: 50px 0 0 0;
  padding: 0 17px;
  color: #fff;
  background: url(../images/bg_footer.png) top left repeat-x;
}
#footer span {
  float: right;
  padding: 0 0 0 0;
}
#footer a {
  text-decoration: underline;
  color: #fff;
}
#footer a:hover {
  text-decoration: none;
}
