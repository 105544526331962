
/* ------------------------------------- */
/* STANDARD HTML TAG RESET               */
/* ------------------------------------- */

html, body, div, span, iframe,
h1, h2, h3, h4, h5, h6,
p, blockquote, a, cite, em, img, dl, dt, dd, ol, ul, li
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-weight: inherit;
	font-style: inherit;
	font-size: 100%;
	font-family: inherit;
	vertical-align: baseline;
}

ol, ul {
	list-style: none;
}

table {
	border-collapse: separate;
	border-spacing: 0;
}

caption, th, td {
	text-align: left;
	font-weight: normal;
}

q:before, q:after { content: ''; }

