
/* ------------------------------------- */
/* STANDARD HTML TAG RESET               */
/* ------------------------------------- */

html, body, div, span, iframe,
h1, h2, h3, h4, h5, h6,
p, blockquote, a, cite, em, img, dl, dt, dd, ol, ul, li
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-weight: inherit;
	font-style: inherit;
	font-size: 100%;
	font-family: inherit;
	vertical-align: baseline;
}

ol, ul {
	list-style: none;
}

table {
	border-collapse: separate;
	border-spacing: 0;
}

caption, th, td {
	text-align: left;
	font-weight: normal;
}

q:before, q:after { content: ''; }


/* ---------------------------------------------------------------------------------------------------------------------------------------

HEADER STYLES - LOGO, UTILITIES

--------------------------------------------------------------------------------------------------------------------------------------- */

#header {
  position: relative;
  width: 862px;
  height: 130px;
}
#header .tagline {
  position: absolute;
  bottom: 50px;
  right: 0;
  font-family: Times New Roman;
  font-size: 17px;
  line-height: 20px;
  font-weight: bold;
  color: #002e00;
  text-align: right;
  text-transform: uppercase;
}
#header .tagline span {
  color: #003300;
  font-family: Verdana;
  font-size: 11px;
  text-transform: none;
  font-weight: normal;
}

#adminHeader {
  position: relative;
  width: 862px;
  height: 20px;
}
#adminHeader .tagline {
  position: absolute;
  bottom: 20px;
  right: 0;
  font-family: Times New Roman;
  font-size: 17px;
  line-height: 20px;
  font-weight: bold;
  color: #002e00;
  text-align: right;
  text-transform: uppercase;
}
#adminHeader .tagline span {
  color: #003300;
  font-family: Verdana;
  font-size: 11px;
  text-transform: none;
  font-weight: normal;
}

#header .nav {
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 862px;
}
#header .nav ul {
  width: 862px;
  height: 39px;
  background: url(/static/media/bg_nav.6f205d7f.png) top left repeat-x;
}
#header .nav li {
  float: left;
  display: block;
  list-style-type: none;
}
#header .nav a {
  width: 143px;
  display: block;
  height: 39px;
  line-height: 39px;
  color: #fff;
  font-family: Times New Roman;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
#header .nav a.current,
#header .nav a:hover,
#header .nav a.active {
  color: #003300;
}
#header .nav a.home {
  width: 120px;
}
#header .nav a.about {
  width: 145px;
}
#header .nav a.facilities {
  width: 158px;
}
#header .nav a.winners {
  width: 147px;
}
#header .nav a.gallery {
  width: 144px;
}
#header .nav a.contact {
  width: 147px;
}

/* ---------------------------------------------------------------------------------------------------------------------------------------

MAINCONTENT STYLES - BANNER, BREADRUMBS, CONTENT, FILE ICONS

--------------------------------------------------------------------------------------------------------------------------------------- */
#content {
  display: flex;
  flex-flow: row;
  min-height: 50vh;
}

#content.home {
  flex-flow: column;
}

#content .left {
  float: left;
  width: 415px;
}
#content .right {
  float: right;
  width: 415px;
}
.homeBanner {
  border-bottom: solid 6px #819000;
}

#content h1 {
  padding: 0 0 10px 0;
  font-family: Times New Roman;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #002e00;
  text-transform: uppercase;
}
#content h2 {
  padding: 0 0 10px 0;
  font-family: Times New Roman;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #002e00;
  text-transform: uppercase;
}
#content h3 {
  padding: 0 0 4px 0;
  font-family: Verdana;
  font-size: 11px;
  line-height: 16px;
  font-weight: bold;
  color: #002e00;
  text-transform: uppercase;
}
#content p {
  font-size: 11px;
  line-height: 18px;
  padding: 0 0 10px 0;
}

.homeContent {
  width: 862px;
  margin: 20px 0 0 0;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA14AAAAJCAIAAACt2Wp3AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHdJREFUeNrs2zERgDAQBMAEF5Q0WEANVjBCgQlMREVKZIRhaDKx8LsSrvq5m8+ttQQAAClNIgDCOs5VCAC9rDUEAOCnNQTiqk8RAoDTEOBz3bsQAHoGZSCu+pRl3uQA4DQEAGBkUAbi8qEMMNAaAnEZlAEGrwADAOyNG7wqAkLQAAAAAElFTkSuQmCC) top center repeat-y;
}

.leftNav {
  width: 185px;
  padding: 24px 0 0 0;
}

.leftNav li {
  font-family: Times New Roman;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-weight: bold;
  border-bottom: dotted 1px #7d9a25;
  list-style-type: none;
  color: #87a000;
  text-transform: uppercase;
}
.leftNav a {
  display: block;
  width: 185px;
}

.leftNav ul li a.active {
  color: #87a000;
}

.mainContent {
  position: relative;
  float: left;
  width: 477px;
  min-height: 400px;
  padding: 30px 0 0 48px;
}
.mainContent .header {
  position: relative;
  top: -4px;
  padding: 0 0 15px 0;
}

.mainContent .header.tagline {
  position: relative;
  width: 480px;
  padding: 0;
  top: 2px;
}

.mainContent .header.tagline .text {
  width: 480px;
  padding: 5px 0 5px 10px;
  padding-left: 5px;
  margin-bottom: 15px;
  background: #002e00;
  color: #fff;
  text-transform: capitalize;
  font-weight: bold;
}

.mainContent ul {
  padding: 0 0 15px 0;
}

.mainContent ul li.bold {
  font-weight: bold;
}

.mainContent .winners {
  text-transform: uppercase;
}

.thumbnails {
  width: 246px;
  padding: 30px 0 0 0;
}
.thumbnails .thumb {
  width: 77px;
  height: 67px;
  padding: 0 5px 10px 0;
}
.thumbnails .arrow {
  display: block;
  width: 241px;
  height: 16px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPEAAAAQCAYAAADH05kuAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAo1JREFUeNrsmr9qFFEUxs8dU/gAVha+hTYKQUhv4Rv4BkoCCWoRFCSkiNpErARRsBGCCBaihWBhZRAbbYONqCiKsLv3HL/7Z2bPiiFWkx34vuXu/TtTnOE33z0zE8xMqOHp8cuwi+oErl5M/ZD+2kvZoGl1rBX6VgeCWxqsDgT8LHevnT9rW4zwcLTAEAxTE5X7AHUzVCLNw2kVzkqsOcA7sB3JLdzoP2gaucfoEmKqBwHg26pyBvyda0EN5hy2whl0OuZdOYQKuHVkv0e1GqN8Y3QJMdWDNMpITVbQPAkoj7eO6kHOMLeOGzr4u3Gzrp5g/vLCEdljZIenhiEYKMSa4fyIeg1b6zhJyTGy41ynomVNHtPS93XbTvNYvw2Qd3BTEOUjEjox1Y8SgBlmkYeAdRHNC95xZ/Jec7drdXNlfhduvJ6WxMi40omp/iCujpu3wiJXTeVD2h6blm1yclSLxY0zx23bCsda1v5Cfxnli5VzCV9W0Impnp24JLryCYCugL9HaB8Vlweby4u7HLgcI6GRm7iLP2c06cTUYUAcXdHsrk9Qb+d8N07zXfVrdOa413DiTe/AdGI6MdWjVGf7mT+V64DwNLqn1KXEQVyuXCZ+wJEvAvDvylDSiak5cOLquAD4K3LdZQD+M0Genza3jjx9Ep1c+QZO8YZRJMTUISrltv8q0CvAu9Fto+sW2W2pn8Gxb43HIqN9CsXtNNWDRqP95wDtFrhdBKxLCeyJdG+ZPqNaC438ZgQJMTVnOfFfSq+OLmHJC9B7zKZwrzeNvBUmwoSYmoOc+OCnyO/A6hVAfMeKDe8Ek7vKDzqYE1Nz4sT/USxBq/JU08ccKqsWZJy+rz6oUMPSHwEGAA1gkw6AhTKIAAAAAElFTkSuQmCC) top left no-repeat;
}
.thumbnails .arrow:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPEAAAAQCAYAAADH05kuAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAq1JREFUeNrsW7FqFUEUvTMvgh9gZeFfaKOQxt5/sLFWEkhQIUFBJEXUJqCNIAqWQQQLsREsrAxio63YiIqiiG/33vHMzN3HJujTah8L58DdmTszm+LC2XPu7ktIKQkxPpzbCHsYjpmI5jzmi/nmEqbmax2Qmy/E3tFovhAlYDEgvXJnI22zwuPBEkswTjQq90DUreiMbPrkTJWcHbutI7jWM9pjcp5b8kWT+5OJ3GV1SWJiAIDAt0zlVFI5Aw3NvJVsqoqvUs+xHnzeE+RyCZnEod7jsvwG6Vpr8oXVJYmJIUjcyBQKuorpcZDwaFmLVYGtd65T6s5bm2/G5JY7lrHF/sVDS/KelR0fIkswUhJX+/tOVdZhrbUBKRWeuow5tJ5pm2K9pdU66sF5U87vJJPdbKuNr0ioxMQwaHVmjx+ArMuw0GeLbw4HHtFWe2SZ+JrfV87V/b2QZLO0xS3rSiUmBoO64qKnzdS7DCV9W3piq31uVtTUVstc+uO2KnPZE99X+YF8Bfd8Kvd5EFRiYggSay9J8gEEXQUvH2J+uKisq3IZPM8EDr6WLyHKDTzFn7KaVGJiESRue6FFXR9h3Mk2u/W1vGf9M7rvvhdQ3a3k5O4HQSUmBoDp/rzwz+QqSHgS6QnztnfWJodeTxzlGxT5PAj+le6ZSkws0E73w/vdz+iBVzD/nvPcD3fqO1Ph+ub6Gv7ES1aRJCYWiNzb/imA55bkunXkdousHalNnkCxbzZTkelfgqCdJgbA9Nc8ry3b4O0yyHs6E7v7yoQn9kek6zKRn6wgSUwsGEnnbudPRxdA3mdg75HuXRXWNmOUV1FZP9ppYvE9cZofIPBrDJdm345FdjG/nW11084PgiQmhlDi/wmQFj3wY/TF+cccaylIk/8p4l9BjAu/BRgA+V3CklrcZcoAAAAASUVORK5CYII=) top left no-repeat;
}

.facilitiesMain {
  width: 485px;
  padding: 60px 0 0 25px;
}

/*******GALLERY********/
.galleryWrapper {
  padding: 30px 0 0 0;
}

#gallery {
  display: block;
}

div.content {
  /* The display of content is enabled using jQuery so that the slideshow content won't display unless javascript is enabled. */
  display: none;
  float: left;
  width: 487px;
}
div.content a,
div.navigation a {
  text-decoration: none;
  color: #777;
}
div.content a:focus,
div.content a:hover,
div.content a:active {
  text-decoration: underline;
}
div.controls {
  margin-top: 5px;
}
div.controls a {
  padding: 5px;
}
div.ss-controls {
  float: left;
}
div.nav-controls {
  float: right;
}
div.slideshow-container {
  position: relative;
  clear: both;
  height: 407px; /* This should be set to be at least the height of the largest image in the slideshow */
}

div.slideshow span.image-wrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
div.slideshow a.advance-link {
  display: block;
  width: 487px;
  height: 407px; /* This should be set to be at least the height of the largest image in the slideshow */
  text-align: center;
}
div.slideshow a.advance-link:hover,
div.slideshow a.advance-link:active,
div.slideshow a.advance-link:visited {
  text-decoration: none;
}
div.slideshow img {
  vertical-align: middle;
  border: 1px solid #ccc;
}
div.download {
  float: right;
}
div.caption-container {
  position: relative;
  clear: left;
  height: 75px;
}
span.image-caption {
  display: block;
  position: absolute;
  width: 487px;
  top: 0;
  left: 0;
}
div.caption {
  padding: 12px;
}
div.image-title {
  font-weight: bold;
  font-size: 1.4em;
}
div.image-desc {
  line-height: 1.3em;
  padding-top: 12px;
}

div#thumbs {
  width: 300px;
  float: left;
  opacity: 1;
}

ul.thumbs {
  position: relative;
  clear: both;
  margin: 0;
  padding: 0;
}
ul.thumbs li {
  float: left;
  padding: 0;
  margin: 5px 10px 5px 0;
  list-style: none;
}

.hidden {
  display: none;
}

a.thumb {
  padding: 2px;
  display: block;
  border: 1px solid #ccc;
  cursor: pointer;
}

ul.thumbs li {
  opacity: 0.67;
}
ul.thumbs li.selected {
  opacity: 1;
}
ul.thumbs li.selected a.thumb {
  background: #000;
}
a.thumb:focus {
  outline: none;
}
ul.thumbs img {
  border: none;
  display: block;
}
div.pagination {
  clear: both;
}
div.navigation div.top {
  margin-bottom: 12px;
  height: 11px;
}
div.navigation div.bottom {
  margin-top: 12px;
}
div.pagination a,
div.pagination span.current,
div.pagination span.ellipsis {
  display: block;
  float: left;
  margin-right: 2px;
  padding: 4px 7px 2px 7px;
  border: 1px solid #ccc;
}

div.pagination a {
  cursor: pointer;
}
div.pagination a:hover {
  background-color: #eee;
  text-decoration: none;
}
div.pagination span.current {
  font-weight: bold;
  background-color: #000;
  border-color: #000;
  color: #fff;
}
div.pagination span.ellipsis {
  border: none;
  padding: 5px 0 3px 2px;
}

#footer {
  width: 828px;
  height: 23px;
  line-height: 23px;
  margin: 50px 0 0 0;
  padding: 0 17px;
  color: #fff;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAXCAYAAADKmiUPAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAE1JREFUeNpi3npY8xrTpy+3VZn+/mdgYPr7F0gAGQxM//6AuGCxfyAxEIsBRPz5B1MHlv0HYjEwwMT+gcXABvyDGQXRBpKVFbc+ChBgANZyJ3dH61zHAAAAAElFTkSuQmCC) top left repeat-x;
}
#footer span {
  float: right;
  padding: 0 0 0 0;
}
#footer a {
  text-decoration: underline;
  color: #fff;
}
#footer a:hover {
  text-decoration: none;
}

/* ---------------------------------------------------------------------------------------------------------------------------------------

ADMIN STYLES

--------------------------------------------------------------------------------------------------------------------------------------- */

.admin {
  color: #555;
}

.admin h1 {
  font-size: 2em;
  padding: 0 0 1em;
}

.admin .logged-in-username {
  padding-right: 10px;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.spinner div {
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px;
  -webkit-animation: spinner 1.2s linear infinite;
          animation: spinner 1.2s linear infinite;
}
.spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #002e00;
}
.spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------------------------

GLOBAL STYLES

--------------------------------------------------------------------------------------------------------------------------------------- */

* {
  margin: 0;
  padding: 0;
}

img {
  border: 0;
}
input,
textarea,
select {
  font-size: 1em;
}

table,
th,
td {
  font-size: 1em;
}
div.clearer {
  height: 0;
  font-size: 0px;
  line-height: 0px;
  clear: both;
}
a,
a:visited {
  color: #32541b;
  text-decoration: none;
}
a:hover {
  color: #7d9a25;
  text-decoration: none;
}
.align_left {
  float: left;
}
.align_right {
  float: right;
}

html,
body {
  min-height: 100vh;
}

.site-bg {
  background: #233017 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAACWAAAAACCAIAAABTi3C7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAKZJREFUeNrs2VEKwjAMAFDjJTyAF/D+l4t+ri5pERSKvPfXtEkgrGOwuD9uF2AHYQSw5aWM6VWNMRBxPlnUOW5FVTnarOi6NHXeuyxSYtW6GkUMBYqsYmsMr4ZcvCO9MwEA2EdWq5ydeS2HQOb5ZFHnuJVV5WyzsuvyYZ0+Jaetq1Ech1BmVV2G8GrIf/FAAe4k/MTVCACAzb95/Q0EAAAAgC96CjAAf+M4bph+bBcAAAAASUVORK5CYII=) top center repeat-y;
  font: 11px Verdana, Arial, sans-serif;
  text-align: left;
  color: #333;
  width: 100%;
  min-height: 100vh;
}

#wrapper {
  width: 919px;
  margin: 0px auto;
  text-align: left;
  padding: 35px 0;
}
#top {
  height: 27px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA5cAAAAbCAYAAADoD3vbAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAo1JREFUeNrs3cuLjXEcx/HnmDFyy22UpBRJYoENkSjJZWFBNoiVDSKyR0rZsFL+A0lZWciOvbJBKZTIjmyQyxyfn3OOjonmcsbtzOtV707neWoW39233/PMqdXr9aoDtdSTetOStDKtSavT/LQwDVQAAAD8bWV/e56epvvpXrqbXqWyGH7p6I93uFyWhXJf2pRWNJfMvjQxTWgGAADAv6EsgJ+bfUrv0oN0Nd1Ib/70crkuHakap5T9aVrVOMEEAADg//KluVS+TnfSpfTody+Xy9KJtDEtqhonlQAAAHSH9+llup0upidjvVyWx1wPpYNV473KPjMHAADoWq3HZS+na+nDWCyX5YTyWDqQZpkxAADAuPGiaryPWR6VfdXJcrkqnUlb0yRzBQAAGHc+puvpfHo4muWy/PfXK2m9WQIAAIx7t9Lx9PhnN3/1UyHlUdgLFksAAACatqXTacFwl8t56WzaYXYAAAC02ZtOpSlDLZflvcrDab+ZAQAA8BNH0+7BFwe/c1l+v/JmmmZeAAAA/MLbqvEPYJ+1LrSfXM5MRyyWAAAADGF6Otd+oX25XJv2mBEAAABDKLvklrR88HI5Ix0zHwAAAIapP50cvFzOTdvNBgAAgGEq++SGqvGK5bcvPWmzuQAAADBC5aCy/IxlT1ku+9IuMwEAAGCEyqnlztRblsuJVeMoEwAAAEZqaZpdlss5abJ5AAAAMArl5yxXlOVysVkAAADQwXK5vCyXU80CAACAUeqtmo/FfjILAAAAOlCfYAYAAABYLv+FKdbr3z9btd9r/w4AANCNLJdjoFar/bBEls+BgYHv91pa1wAAALpNrxF0rrVYlkWyVfu91mf7dQAAgG7yVYABADpkhm9lhPrXAAAAAElFTkSuQmCC) top left no-repeat;
}
#middle {
  min-height: 300px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA5cAAAABCAYAAABKQlomAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNrswzENACAAA7BhD39IwRUeBhb426Sj7UyyAwAAAP/Ou64AAwBTBAV/ZQRk5wAAAABJRU5ErkJggg==) top left repeat-y;
  padding: 0 30px 0 25px;
}
#bottom {
  height: 30px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA5cAAAAeCAYAAAC4wupoAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAo5JREFUeNrs3T1sTWEYwPG3t0KIWCU2FpsYGSQSYZAwmhmsVoP4iJWIgRADi50EA+JjqApRXxFikSCpRBRVol96PG/uPXE1be9XE9X+fsm/ubc99wzvmZ6c9552FUWxPaV0LQEAAEDrPkWnK9YBAACAThkuAQAAMFwCAABguAQAAMBwCQAAAIZLAAAAZmm47LYMAAAAdDBXLs4/Rq0FAAAAbRqPvuXh8r21AAAAoE1D0dM8XPZHw9YDAACANvyIXpXbYh9aDwAAANqQd8MOlMPlVesBAABAi/Jdy5vRSB4ux2tvAAAAoBUD0aVorPw/l2+jXusCAABAC/qid/lFOVwORqesCwAAAE3Kdy3Plm/K4XIiuhXdtz4AAAA0UEQ90Y3Jw2X2MTpmjQAAAGggP8jnaP0vKpMOyA/2OW6dAAAAmEbe+Xo4ejzTcPk9OpPqbm0CAABAnSvR+cm/rExx4JvoQPL9SwAAAP52NzoSfW1muMweRQejl9YOAACAcC86FD2b6o+VGT54O9oXPbGGAAAAC9qdVN3hmgfMYqoDuoqiaHSSjdH+aFu01JoCAAAsGHn76+XoZPR8usGy2eEyWxPtjXZFq/PnrDEAAMC89jq6EJ2LvjQ6uNnhsrQz2hNtiFYaMgEAAOaV8ag/Vb9XeSJVH+DTlFaHy2xJtDv9uYuZh8xlrgEAAMB/azj6kKpbXy9G16OhVk7QznBZ6o62RjuiLdGKaHmqfi9zkWsDAAAwZ41EP2t9jnpqQ+WDaKydE3YyXNbLg+XmaFO0PlpbN2QWrhsAAMCckAfHvPW1N1W3vvbVXg92euLZGi6zSl2/olXRumjU9QMAAPjn8jNzXqTqncqJWnl2m5Wh8LcAAwDD5n8IRpZStQAAAABJRU5ErkJggg==) bottom left no-repeat;
}

