/* ---------------------------------------------------------------------------------------------------------------------------------------

MAINCONTENT STYLES - BANNER, BREADRUMBS, CONTENT, FILE ICONS

--------------------------------------------------------------------------------------------------------------------------------------- */
#content {
  display: flex;
  flex-flow: row;
  min-height: 50vh;
}

#content.home {
  flex-flow: column;
}

#content .left {
  float: left;
  width: 415px;
}
#content .right {
  float: right;
  width: 415px;
}
.homeBanner {
  border-bottom: solid 6px #819000;
}

#content h1 {
  padding: 0 0 10px 0;
  font-family: Times New Roman;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #002e00;
  text-transform: uppercase;
}
#content h2 {
  padding: 0 0 10px 0;
  font-family: Times New Roman;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #002e00;
  text-transform: uppercase;
}
#content h3 {
  padding: 0 0 4px 0;
  font-family: Verdana;
  font-size: 11px;
  line-height: 16px;
  font-weight: bold;
  color: #002e00;
  text-transform: uppercase;
}
#content p {
  font-size: 11px;
  line-height: 18px;
  padding: 0 0 10px 0;
}

.homeContent {
  width: 862px;
  margin: 20px 0 0 0;
  background: url(../images/bg_center_dots.png) top center repeat-y;
}

.leftNav {
  width: 185px;
  padding: 24px 0 0 0;
}

.leftNav li {
  font-family: Times New Roman;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-weight: bold;
  border-bottom: dotted 1px #7d9a25;
  list-style-type: none;
  color: #87a000;
  text-transform: uppercase;
}
.leftNav a {
  display: block;
  width: 185px;
}

.leftNav ul li a.active {
  color: #87a000;
}

.mainContent {
  position: relative;
  float: left;
  width: 477px;
  min-height: 400px;
  padding: 30px 0 0 48px;
}
.mainContent .header {
  position: relative;
  top: -4px;
  padding: 0 0 15px 0;
}

.mainContent .header.tagline {
  position: relative;
  width: 480px;
  padding: 0;
  top: 2px;
}

.mainContent .header.tagline .text {
  width: 480px;
  padding: 5px 0 5px 10px;
  padding-left: 5px;
  margin-bottom: 15px;
  background: #002e00;
  color: #fff;
  text-transform: capitalize;
  font-weight: bold;
}

.mainContent ul {
  padding: 0 0 15px 0;
}

.mainContent ul li.bold {
  font-weight: bold;
}

.mainContent .winners {
  text-transform: uppercase;
}

.thumbnails {
  width: 246px;
  padding: 30px 0 0 0;
}
.thumbnails .thumb {
  width: 77px;
  height: 67px;
  padding: 0 5px 10px 0;
}
.thumbnails .arrow {
  display: block;
  width: 241px;
  height: 16px;
  background: url(../images/arrow_thumbnails.png) top left no-repeat;
}
.thumbnails .arrow:hover {
  background: url(../images/arrow_thumbnails_over.png) top left no-repeat;
}

.facilitiesMain {
  width: 485px;
  padding: 60px 0 0 25px;
}

/*******GALLERY********/
.galleryWrapper {
  padding: 30px 0 0 0;
}

#gallery {
  display: block;
}

div.content {
  /* The display of content is enabled using jQuery so that the slideshow content won't display unless javascript is enabled. */
  display: none;
  float: left;
  width: 487px;
}
div.content a,
div.navigation a {
  text-decoration: none;
  color: #777;
}
div.content a:focus,
div.content a:hover,
div.content a:active {
  text-decoration: underline;
}
div.controls {
  margin-top: 5px;
}
div.controls a {
  padding: 5px;
}
div.ss-controls {
  float: left;
}
div.nav-controls {
  float: right;
}
div.slideshow-container {
  position: relative;
  clear: both;
  height: 407px; /* This should be set to be at least the height of the largest image in the slideshow */
}

div.slideshow span.image-wrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
div.slideshow a.advance-link {
  display: block;
  width: 487px;
  height: 407px; /* This should be set to be at least the height of the largest image in the slideshow */
  text-align: center;
}
div.slideshow a.advance-link:hover,
div.slideshow a.advance-link:active,
div.slideshow a.advance-link:visited {
  text-decoration: none;
}
div.slideshow img {
  vertical-align: middle;
  border: 1px solid #ccc;
}
div.download {
  float: right;
}
div.caption-container {
  position: relative;
  clear: left;
  height: 75px;
}
span.image-caption {
  display: block;
  position: absolute;
  width: 487px;
  top: 0;
  left: 0;
}
div.caption {
  padding: 12px;
}
div.image-title {
  font-weight: bold;
  font-size: 1.4em;
}
div.image-desc {
  line-height: 1.3em;
  padding-top: 12px;
}

div#thumbs {
  width: 300px;
  float: left;
  opacity: 1;
}

ul.thumbs {
  position: relative;
  clear: both;
  margin: 0;
  padding: 0;
}
ul.thumbs li {
  float: left;
  padding: 0;
  margin: 5px 10px 5px 0;
  list-style: none;
}

.hidden {
  display: none;
}

a.thumb {
  padding: 2px;
  display: block;
  border: 1px solid #ccc;
  cursor: pointer;
}

ul.thumbs li {
  opacity: 0.67;
}
ul.thumbs li.selected {
  opacity: 1;
}
ul.thumbs li.selected a.thumb {
  background: #000;
}
a.thumb:focus {
  outline: none;
}
ul.thumbs img {
  border: none;
  display: block;
}
div.pagination {
  clear: both;
}
div.navigation div.top {
  margin-bottom: 12px;
  height: 11px;
}
div.navigation div.bottom {
  margin-top: 12px;
}
div.pagination a,
div.pagination span.current,
div.pagination span.ellipsis {
  display: block;
  float: left;
  margin-right: 2px;
  padding: 4px 7px 2px 7px;
  border: 1px solid #ccc;
}

div.pagination a {
  cursor: pointer;
}
div.pagination a:hover {
  background-color: #eee;
  text-decoration: none;
}
div.pagination span.current {
  font-weight: bold;
  background-color: #000;
  border-color: #000;
  color: #fff;
}
div.pagination span.ellipsis {
  border: none;
  padding: 5px 0 3px 2px;
}
