/* ---------------------------------------------------------------------------------------------------------------------------------------

ADMIN STYLES

--------------------------------------------------------------------------------------------------------------------------------------- */

.admin {
  color: #555;
}

.admin h1 {
  font-size: 2em;
  padding: 0 0 1em;
}

.admin .logged-in-username {
  padding-right: 10px;
}
