/* ---------------------------------------------------------------------------------------------------------------------------------------

GLOBAL STYLES

--------------------------------------------------------------------------------------------------------------------------------------- */

@import url('reset.css');
@import url('module_header.css');
@import url('module_maincontent.css');
@import url('module_footer.css');
@import url('module_admin.css');
@import url('spinner.css');

* {
  margin: 0;
  padding: 0;
}

img {
  border: 0;
}
input,
textarea,
select {
  font-size: 1em;
}

table,
th,
td {
  font-size: 1em;
}
div.clearer {
  height: 0;
  font-size: 0px;
  line-height: 0px;
  clear: both;
}
a,
a:visited {
  color: #32541b;
  text-decoration: none;
}
a:hover {
  color: #7d9a25;
  text-decoration: none;
}
.align_left {
  float: left;
}
.align_right {
  float: right;
}

html,
body {
  min-height: 100vh;
}

.site-bg {
  background: #233017 url(../images/bg_main.png) top center repeat-y;
  font: 11px Verdana, Arial, sans-serif;
  text-align: left;
  color: #333;
  width: 100%;
  min-height: 100vh;
}

#wrapper {
  width: 919px;
  margin: 0px auto;
  text-align: left;
  padding: 35px 0;
}
#top {
  height: 27px;
  background: url(../images/bg_main_corners_top.png) top left no-repeat;
}
#middle {
  min-height: 300px;
  background: url(../images/bg_main_middle.png) top left repeat-y;
  padding: 0 30px 0 25px;
}
#bottom {
  height: 30px;
  background: url(../images/bg_main_corners_bottom.png) bottom left no-repeat;
}
