/* ---------------------------------------------------------------------------------------------------------------------------------------

HEADER STYLES - LOGO, UTILITIES

--------------------------------------------------------------------------------------------------------------------------------------- */

#header {
  position: relative;
  width: 862px;
  height: 130px;
}
#header .tagline {
  position: absolute;
  bottom: 50px;
  right: 0;
  font-family: Times New Roman;
  font-size: 17px;
  line-height: 20px;
  font-weight: bold;
  color: #002e00;
  text-align: right;
  text-transform: uppercase;
}
#header .tagline span {
  color: #003300;
  font-family: Verdana;
  font-size: 11px;
  text-transform: none;
  font-weight: normal;
}

#adminHeader {
  position: relative;
  width: 862px;
  height: 20px;
}
#adminHeader .tagline {
  position: absolute;
  bottom: 20px;
  right: 0;
  font-family: Times New Roman;
  font-size: 17px;
  line-height: 20px;
  font-weight: bold;
  color: #002e00;
  text-align: right;
  text-transform: uppercase;
}
#adminHeader .tagline span {
  color: #003300;
  font-family: Verdana;
  font-size: 11px;
  text-transform: none;
  font-weight: normal;
}

#header .nav {
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 862px;
}
#header .nav ul {
  width: 862px;
  height: 39px;
  background: url(../images/bg_nav.png) top left repeat-x;
}
#header .nav li {
  float: left;
  display: block;
  list-style-type: none;
}
#header .nav a {
  width: 143px;
  display: block;
  height: 39px;
  line-height: 39px;
  color: #fff;
  font-family: Times New Roman;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
#header .nav a.current,
#header .nav a:hover,
#header .nav a.active {
  color: #003300;
}
#header .nav a.home {
  width: 120px;
}
#header .nav a.about {
  width: 145px;
}
#header .nav a.facilities {
  width: 158px;
}
#header .nav a.winners {
  width: 147px;
}
#header .nav a.gallery {
  width: 144px;
}
#header .nav a.contact {
  width: 147px;
}
